import { platformEnum } from '../../config'
import FavorateButton from "../favorates/Button"
import { Card, CardMedia, CardContent, CardActions, Box } from '@mui/material'
import useItemStore from '../../stores/itemStore'

export default function ({ item }) {
  const setCurrentItem = useItemStore(s => s.setCurrentItem)
  const setIsModalOpen = useItemStore(s => s.setIsModalOpen)

  return <Card sx={{ maxWidth: 345 }}>
    <CardMedia
      component='img'
      sx={{ maxHeight: 345 }}
      src={item.goodsThumbUrl}
      alt={item.goodsThumbUrl}
      onClick={() => {
        setIsModalOpen(true)
        // dispatch(setIsDrawerOpen(false))
        setCurrentItem(item)
      }}
    ></CardMedia>
    <CardContent sx={{ '& > :not(style)': { mt: 1, }, pb: 1 }}>
      <div style={{ fontWeight: 'bold' }}>{item.goodsName}</div>
      <div style={{ color: '#000000D9', fontWeight: 'normal' }}>
        <img src={platformEnum[item.source]?.icon} alt={item.source} style={{ width: 16 }}></img>
        {item.shopName}  销量{item.sales_tip}
      </div>
      <div style={{ color: '#000000D9', fontWeight: 'normal' }}>
        <span style={{ color: 'red', fontWeight: 'bold', fontSize: '1.3em' }}>
          {`${(item.price - item.commission * 0.85)?.toLocaleString('zh-cn', { style: "currency", currency: "CNY" })}`}
        </span>
        <span style={{ color: '#00000099', textDecoration: 'line-through' }}>{parseFloat(item.price).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })}</span>
        <span>{` 佣金${parseFloat(item.commission).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })}(${item.commissionRate}%)`}</span>
      </div>
    </CardContent>
    <CardActions sx={{ pt: 0 }}>
      <Box sx={{ flexGrow: 1 }} textAlign='center'>
        <FavorateButton fullWidth key='favorate' item={item}></FavorateButton>
      </Box>
    </CardActions>
  </Card>
}