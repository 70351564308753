import FavorateButton from "../favorates/Button"
import useEventStore from "../../stores/eventStore"
import { Card, CardMedia, CardContent, CardActions, Box } from '@mui/material'

export default function ({ item }) {
  const setCurrentItem = useEventStore(s => s.setCurrentItem)
  const setIsModalOpen = useEventStore(s => s.setIsModalOpen)

  return <Card sx={{ maxWidth: 345 }}>
    <CardMedia
      component='img'
      sx={{ maxHeight: 345 }}
      src={item?.img}
      alt={item?.img}
      onClick={() => {
        setIsModalOpen(true)
        setCurrentItem(item)
      }}
    ></CardMedia>
    <CardContent sx={{ '& > :not(style)': { mt: 1, }, pb: 1 }}>
      <div style={{ fontWeight: 'bold' }}>{item?.act_name}</div>
      <div style={{ color: '#000000D9', fontWeight: 'normal' }}>
        {`佣金 ${item?.commission_rate}`}
      </div>
    </CardContent>
    <CardActions sx={{ pt: 0 }}>
      <Box sx={{ flexGrow: 1 }} textAlign='center'>
        <FavorateButton fullWidth key='favorate' item={item}></FavorateButton>
      </Box>
    </CardActions>
  </Card>
}
