import { create } from 'zustand'
import axios from '../axios'
import { persist } from 'zustand/middleware'

const useEventStore = create(persist(
  (set, get) => ({
    isModalOpen: false,
    currentItem: {},
    itemList: [],
    allItems: [],
    knownItems: {},
    cacheExpire: 0,
    setIsModalOpen: (value) => set({ isModalOpen: value }),
    setCurrentItem: (value) => set({ currentItem: value }),
    clear: () => set({ itemList: [] }),
    addItems: (data) => set((state) => {
      if (data && Array.isArray(data) && data.length > 0) {
        return { itemList: [...state.itemList, ...data] }
      }
    }),
    search: async ({ keyword, cb }) => {
      const { allItems, cacheExpire } = get()
      const filter = (items) => {
        if (keyword && typeof (keyword) === 'string' && keyword.length) {
          return { filtered: items.filter(x => new RegExp(keyword).test(x.act_name)) }
        }
        else {
          return { all: items }
        }
      }
      var result
      if (+new Date() < cacheExpire && allItems && allItems.length) {
        result = filter(allItems)
      } else {
        result = await axios.post('/act_list', {}, { timeout: 10000 })
          .then(res => {
            const data = res.data.data
            set({ cacheExpire: +new Date() + 1000 * 3600 * 24 * 7 })
            return filter(data)
          }).catch(() => { cb() })
      }

      const { filtered, all } = result
      set({ itemList: filtered || all })
      if (all) {
        set({ allItems: all })
      }
    },
    convert: async ({ success, failed }) => {
      const { knownItems, currentItem } = get()
      const { act_id } = currentItem
      const itemKey = act_id?.toString()
      if (itemKey && knownItems[itemKey]) {
        set({ currentItem: knownItems[itemKey] })
      }
      return await axios.post('/act', { act_id }, { timeout: 10000 })
        .then(async res => {
          var item = { ...currentItem, ...res.data }
          if (item) {
            // url 
            let url = item.h5 || item.long_h5
            item.url = url

            item.act_id = act_id

            const pics = Array.from(new Set([
              item.poster_qrcode_url,
              item.we_app_info?.miniCode,
              item.alipay_app_info?.miniCode,
              currentItem.poster,
              currentItem.img,
            ])).filter(x => x)

            item.pics = pics
            if (typeof (success) === 'function') {
              success()
            }

            set({ currentItem: item, knownItems: { ...knownItems, [item.act_id]: item } })
            return item
          }

        }).catch(e => {
          if (typeof (failed) === 'function') {
            failed()
          }
        })
    }
  }),
  { name: 'event-storage' }
))

export default useEventStore