import * as React from 'react';
import { useRef, useState } from 'react'
import { platformEnum, sortEnum } from '../config'
import _ from 'lodash'
import FavorateListDrawer from '../components/favorates/Drawer'
import ItemDetailModal from '../components/items/ItemDetailModal'
import ItemList from '../components/items/ItemList'
import {
  Input, Select, MenuItem, Fab, Box, IconButton, Grid,
  Dialog, DialogTitle, DialogActions, DialogContent, Button,
  FormControl, FormLabel, FormControlLabel, Radio, RadioGroup
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowUp, Favorite, ContentPaste, Search, Close, Settings, Sort } from '@mui/icons-material/';
import useItemStore from '../stores/itemStore'
import useFavorateStore from '../stores/favorateStore'
import { PageSizeRadioGroup, SortSelect, SourceSelect, Filters } from '../components/items/Settings';

export default function Home() {
  const [sort, setSort] = useState('1')
  const theme = useTheme();

  const dataSource = useItemStore(state => state.itemList)
  const setKeyword = useItemStore(state => state.setKeyword)
  const search = useItemStore(state => state.search)
  const aggregateSearch = useItemStore(state => state.aggregateSearch)

  const setIsDrawerOpen = useFavorateStore(state => state.setIsDrawerOpen)

  function handleSearch() {
    aggregateSearch({ sort })
  }

  function SettingsDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <React.Fragment>
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <Settings></Settings>
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>搜索设置</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', '& > :not(style)': { pb: 1 } }}>
            <SortSelect showLabel></SortSelect>
            <PageSizeRadioGroup showLabel></PageSizeRadioGroup>
            <Filters showLabel></Filters>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSearch} color='primary'>Search</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  function SearchForm() {
    const inputRef = useRef()
    const keyword = useItemStore(state => state.keyword)

    return (
      <Box
        sx={{
          m: 1,
          flexGrow: 1
        }}
      >
        <Grid container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ '& > :not(style)': { mr: 2 }, alignItems: 'center' }}
        >
          <Grid>
            <SettingsDialog></SettingsDialog>
          </Grid>
          <Grid>
            <SourceSelect></SourceSelect>
          </Grid>
          <Grid xs={4} sm={4} md={6}>
            <Input
              sx={{ width: '100%' }}
              inputRef={inputRef}
              defaultValue={keyword}
              placeholder='关键词/链接/淘口令'
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  handleSearch()
                }
              }}
              onChange={(e) => setKeyword(e.target.value)}
              endAdornment={<>
                <IconButton
                  size='small'
                  onClick={() => {
                    inputRef.current.value = ''
                    setKeyword('')
                  }}
                  sx={{ visibility: keyword.length ? "visible" : "hidden" }}
                ><Close></Close></IconButton>
                <IconButton
                  size='small'
                  onClick={() => {
                    handleSearch()
                  }}
                ><Search></Search></IconButton>
              </>
              }
            ></Input>

          </Grid>
        </Grid>
      </Box>
    )
  }

  function FloatingActionButtons() {
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );

      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
    return (
      <Box sx={{
        '& > :not(style)': { m: 1 },
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Fab color="secondary"
          onClick={() => setIsDrawerOpen(true)}
        >
          <Favorite></Favorite>
        </Fab>
        <Fab color="primary" aria-label="edit"
          onClick={() => {
            navigator.clipboard.readText().then(text => {
              setKeyword(text)
              search({ keyword: text })
            })
          }}
        >
          <ContentPaste></ContentPaste>
        </Fab>
        <Fab aria-label="like" onClick={handleClick}>
          <KeyboardArrowUp />
        </Fab>
      </Box>
    );
  }
  return (
    <>
      <SearchForm></SearchForm>
      <FloatingActionButtons></FloatingActionButtons>
      <ItemList dataSource={dataSource}></ItemList>
      <ItemDetailModal></ItemDetailModal>
      <FavorateListDrawer page='items'></FavorateListDrawer>
    </>
  )
}
