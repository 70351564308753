import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Button, Container } from "@mui/material";
import { Discount, ShoppingBag } from '@mui/icons-material/';
import Message from "./message/Message";
import Dialog from "./message/Dialog";

const pages = [
  { text: '聚合搜索', icon: <ShoppingBag sx={{ fontSize: 15 }}></ShoppingBag>, url: '/' },
  { text: '返利活动', icon: <Discount sx={{ fontSize: 15 }}></Discount>, url: '/events' },
]

export default function App({ contents }) {
  return (
    <>
      <Message></Message>
      <Dialog></Dialog>
      <AppBar position="fixed" sx={{ zIndex: 1000 }}>
        <Container>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            {pages.map((page) => (
              <Button
                key={page.text}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Link to={page.url}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >{page.icon} {page.text}</Link>
              </Button>
            ))}
          </Box>
        </Container>
      </AppBar>

      <Container>
        <div id="back-to-top-anchor" />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            mt: ['48px', '56px', '64px'],
            p: 3,
          }}
        >
          {contents}
        </Box>
      </Container>
    </>
  );
};