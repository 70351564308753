import { create } from 'zustand'
import axios from '../axios'
import { getItemKey } from '../components/util'
import QRcode from 'qrcode'
import { persist, devtools } from 'zustand/middleware'

function getUniqueValues(arr) {
  var values = arr.flat()
  var set = new Set(values)
  return Array.from(set)
}

const useItemStore = create(
  persist(
    (set, get) => ({
      isModalOpen: false,
      currentItem: {},
      itemList: [],
      knownItems: {},
      sources: ['taobao', 'jd', 'pdd'],
      keyword: '',
      pageSize: 50,
      sort: "1",
      minPrice: 0,
      salesGt0: false,
      setIsModalOpen: (value) => set({ isModalOpen: value }),
      toggleModalOpen: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
      setCurrentItem: (value) => set({ currentItem: value }),
      clear: () => set({ itemList: [] }),
      setPageSize: (value) => set({ pageSize: value }),
      setSort: (value) => set({ sort: value }),
      setMinPrice: (value) => set(() => {
        if (value) {
          return { minPrice: parseFloat(value) }
        }
        return { minPrice: 0 }
      }),
      setSalesGt0: (value) => set({ salesGt0: value }),
      setSources: (value) => set({ sources: value }),
      setKeyword: (value) => set({ keyword: value }),
      search: async ({ keyword, cb }) => {
        await axios.post('/search', { keyword }, { timeout: 10000 })
          .then(res => {
            const { data } = res.data
            if (data && Array.isArray(data)) {
              set({ itemList: data })
            }
          })
          .catch(() => { cb() })
      },
      aggregateSearch: async () => {
        const { clear, sources, keyword, pageSize, sort } = get()
        clear()
        async function req(source) {
          return axios.post('/search', { pageSize, keyword, source, sort }, { timeout: 10000 })
            .then(res => {
              const { data } = res.data
              data.forEach(x => {
                if (x.price && x.commission) {
                  x.finalPrice = x.price - x.commission * 0.85
                } else if (x.price) {
                  x.finalPrice = x.price
                }
              })
              if (data && Array.isArray(data) && data.length > 0) {
                set({ itemList: [...get().itemList, ...data] })
              }
              return data
            })
            .catch(e => {
              console.log(e)
            })
        }
        const result = await Promise.all(sources.map(source => req(source)))
      },
      convert: async ({ success, failed }) => {
        const { knownItems, currentItem } = get()
        const { goodsId, source } = currentItem
        const itemKey = getItemKey(currentItem)
        if (itemKey && knownItems[itemKey]) {
          set({ currentItem: knownItems[itemKey] })
        }
        await axios.post('/convert', { goodsId, source }, { timeout: 10000 })
          .then(async res => {
            var item = res.data
            // url & qr code
            let url
            let deeplinkUrl
            url = item.click_url ? item.click_url : item.url?.match(/(https?:\/\/[^\s]+)/)?.pop()
            url = item.schema_url ? item.schema_url : url
            switch (item.source) {
              case 'taobao':
                url = item.short_click_url
                break
              case 'pdd':
                url = item.url
                deeplinkUrl = item.schema_url
                break
              case 'douyin':
                url = null
                deeplinkUrl = item.click_url
                break
              default:
                url = item.url
                deeplinkUrl = item.deeplinkUrl
            }
            res.data.qr = url ? await QRcode.toDataURL(url) : null
            res.data.url = url
            res.data.deeplinkUrl = deeplinkUrl
            res.data.deeplink_qr = deeplinkUrl ? await QRcode.toDataURL(deeplinkUrl) : null
            // final price
            res.data.finalPrice = (item.price - item.commission * 0.85).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })

            const pics = getUniqueValues([
              item.goodsCarouselPictures || [],
              item.goodsDetailPictures || []
            ])

            res.data.pics = pics
            res.data.goodsThumbUrl = pics[0]

            if (typeof (success) === 'function') {
              success()
            }
            set({ currentItem: res.data, knownItems: { ...knownItems, [getItemKey(res.data)]: res.data } })
          })
          .catch(e => {
            if (typeof (failed) === 'function') {
              failed()
            }
          })
      }
    }),
    { name: 'item-storage' }
  )
)

export default useItemStore