import ItemCard from "./ItemCard"
import { useMemo } from "react"
import { Box } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import useItemStore from "../../stores/itemStore";

export default function ItemList({ dataSource }) {
  const minPrice = useItemStore(s => s.minPrice)
  const salesGt0 = useItemStore(s => s.salesGt0)

  const data = useMemo(() => {
    var sorted = dataSource.slice()
    if (salesGt0) {
      sorted = sorted.filter(x => {
        const { sales_tip } = x
        if (typeof (sales_tip) === 'number') {
          return sales_tip
        }
        else if (typeof (sales_tip) === 'string') {
          const num = x.sales_tip.match(/\d+/).pop()
          return num > 0
        }
        return true
      })
    }
    if (minPrice) {
      sorted = sorted.filter(x => x.price > minPrice)
    }

    sorted.sort((a, b) => a.finalPrice - b.finalPrice)
    return sorted
  }, [dataSource])
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} columns={{ xs: 3, sm: 6, md: 9 }}>
        {data.map((x, index) => <Grid xs={3} key={index}><ItemCard item={x}></ItemCard></Grid>)}
      </Grid>
    </Box>
  )
}