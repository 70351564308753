import {
  Input, Select, MenuItem, Fab, Box, IconButton, Grid,
  Dialog, DialogTitle, DialogActions, DialogContent, Button,
  FormControl, FormLabel, FormControlLabel, Radio, RadioGroup,
  Checkbox, InputLabel, OutlinedInput
} from '@mui/material'
import useItemStore from '../../stores/itemStore'
import { sortEnum, platformEnum } from '../../config'

import { useTheme } from '@mui/material/styles';

export function PageSizeRadioGroup({ showLabel }) {
  const pageSize = useItemStore(s => s.pageSize)
  const setPageSize = useItemStore(s => s.setPageSize)
  return (
    <FormControl>
      {showLabel ? <FormLabel>条目数</FormLabel> : null}
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={pageSize}
        onChange={(e) => { setPageSize(e.target.value) }}
      >
        <FormControlLabel value="20" control={<Radio />} label="20" />
        <FormControlLabel value="50" control={<Radio />} label="50" />
        <FormControlLabel value="100" control={<Radio />} label="100" />
      </RadioGroup>
    </FormControl>
  )
}

function getStyles(name, array, theme) {
  return {
    fontWeight:
      array.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  };
}

export function SortSelect({ showLabel }) {
  const theme = useTheme();
  const sort = useItemStore(s => s.sort)
  const setSort = useItemStore(s => s.setSort)
  return (
    <FormControl>
      {showLabel ? <FormLabel>排序</FormLabel> : null}
      <Select
        variant='standard'
        value={sort}
        autoWidth
        onChange={(e) => {
          const { target: { value } } = e;
          setSort(value)
        }}
      >
        {Object.entries(sortEnum).map(x => <MenuItem
          key={x[0]}
          value={x[0]}
          style={getStyles(x[0], [sort], theme)}
        >{x[1].text}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export function SourceSelect({ showLabel }) {
  const theme = useTheme();
  const sources = useItemStore(s => s.sources)
  const setSources = useItemStore(s => s.setSources)
  return (
    <FormControl>
      {showLabel ? <FormLabel>数据源</FormLabel> : null}
      <Select
        variant='standard'
        multiple
        autoWidth
        onChange={(e) => {
          const { target: { value } } = e;
          setSources(value)
        }}
        value={sources}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <img style={{ width: 20 }} src={platformEnum[value]?.icon} key={value} />
            ))}
          </Box>
        )}
      >
        {Object.entries(platformEnum).map(x => (
          <MenuItem
            key={x[0]}
            value={x[0]}
            sx={getStyles(x[0], sources, theme)}
          >
            <img style={{ width: 20, paddingRight: 5 }} src={x[1].icon} key={x[0]} />
            {x[1].text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function Filters({ showLabel }) {
  const minPrice = useItemStore(s => s.minPrice)
  const setMinPrice = useItemStore(s => s.setMinPrice)
  const salesGt0 = useItemStore(s => s.salesGt0)
  const setSalesGt0 = useItemStore(s => s.setSalesGt0)
  const handleChange1 = (e) => { setSalesGt0(e.target.checked) }
  const handleChange2 = (e) => { setMinPrice(e.target.value) }
  return (
    <>
      <FormControl>
        {showLabel ? <FormLabel>过滤选项</FormLabel> : null}
        <FormControlLabel control={
          <Checkbox
            checked={salesGt0}
            onChange={handleChange1}
          />
        } label="销量大于 0" />
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="component-outlined">最低金额</InputLabel>
        <Input type='number'
          defaultValue={minPrice}
          onChange={handleChange2}
        />
      </FormControl>
    </>
  )
}
